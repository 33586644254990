import React from 'react';
import cx from 'classnames';

import Language from 'constants/Language';
import sanityImgUtil from 'utils/sanityImgUtil';
import { Img, Button } from 'components/base';
import { BlockSocialMedia, SocialMediaImageAndUrl } from 'types';

interface Props {
  block: BlockSocialMedia;
  className?: string;
}

const SocialMediaBlock: React.FC<Props> = ({ className, block }) => {
  if (!block) return null;

  return (
    <div
      className={cx(
        'SocialMediaBlock grid-black-12-columns border-right-black flex flex-col',
        className
      )}
    >
      <Button
        to={block.headerLinkUrl}
        className="text-decoration-none color-black"
        containerClassName="col-12 apercu text-lg bg-color-white border-left-black border-bottom-black block-title-container-padding"
        ariaLabel={Language.t('SocialMedia.genericSocialMediaButtonAriaLabel')}
        label={block.headerLinkLabel}
      />
      <div className="SocialMediaBlock__images-container overflow-x-auto flex flex-row col-12">
        {block.socialMediaImages.map((image: SocialMediaImageAndUrl, index: Number) => (
          <div key={image.url} className="SocialMediaBlock__block flex col-12 md:col-3">
            <Button
              to={image.url}
              className="text-decoration-none w100 h100 relative"
              containerClassName="h100 w100"
              ariaLabel={Language.t('SocialMedia.genericSocialMediaButtonAriaLabel')}
            >
              <div className={cx(`SocialMediaBlock__image-index-${index} w100 absolute`)}>
                <div className="SocialMediaBlock__image-container">
                  <Img
                    className="w100 image-drop-shadow"
                    src={sanityImgUtil(image.image, 400)}
                    alt={image.image.alt || ''}
                  />
                </div>
              </div>
            </Button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SocialMediaBlock;
