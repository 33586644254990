import React from 'react';
import cx from 'classnames';
import get from 'lodash/get';
import withBreakpoints, {
  Breakpoints,
  InjectedProps as WithBreakpointsProps,
} from 'lib/withBreakpoints';

import Language from 'constants/Language';
import { defaultImages } from 'constants/Default';
import sanityImgUtil from 'utils/sanityImgUtil';
import { ImgWithHover, Button } from 'components/base';
import { BlockEvent, BackgroundColorType } from 'types';

interface PassedProps {
  block: BlockEvent;
  className?: string;
}

type Props = PassedProps & WithBreakpointsProps;

const EventBlock: React.FC<Props> = ({ className, block, currentBreakpoint }) => {
  const blockImage = get(block, 'image');
  const defaultImage = defaultImages[2];
  const hasBlackBackground = block.backgroundColor === BackgroundColorType.BLACK;
  const hasBronzeBackground = block.backgroundColor === BackgroundColorType.BRONZE;
  const hasWhiteBackground = block.backgroundColor === BackgroundColorType.WHITE;
  const imageOnLeft = block.alignment === 'image-on-left';
  const breakpointIsMdDown = [
    Breakpoints.MEDIUM.label,
    Breakpoints.SMALL.label,
    Breakpoints.SMALL_EXTRA_SMALL.label,
    Breakpoints.EXTRA_SMALL.label,
  ].includes(currentBreakpoint);

  return (
    <div
      className={cx(`EventBlock flex flex-col bg-color-${block.backgroundColor}`, className, {
        'border-right-black': (hasWhiteBackground || hasBronzeBackground) && imageOnLeft,
      })}
    >
      {block.header && (
        <div
          className={cx(
            `EventBlock__header block-title-container-padding apercu text-lg col-12 bg-color-${block.backgroundColor}`,
            {
              'color-black border-left-black border-top-black border-bottom-black':
                hasWhiteBackground || hasBronzeBackground,
              'color-white border-bottom-white': hasBlackBackground,
            }
          )}
        >
          {block.header}
        </div>
      )}
      <div
        className={cx('EventBlock__container flex flex-col module-image-min-height', {
          'md:flex-row': imageOnLeft,
          'md:flex-row-reverse': !imageOnLeft,
        })}
      >
        <div
          className={cx('md:col-6 z-3 flex p3 lg:p4 flex items-center justify-center', {
            'border-right-black': !imageOnLeft && hasWhiteBackground,
            'grid-white border-right-black border-left-black': hasBlackBackground,
            'grid-black-12-columns': hasWhiteBackground || hasBronzeBackground,
          })}
        >
          {block.url ? (
            <Button
              containerClassName="flex w100"
              className="w100 h100 flex items-center justify-center"
              ariaLabel={Language.t('Global.readButtonAriaLabel', {
                articleTitle: block.linkLabel,
              })}
              to={block.url || ''}
            >
              <ImgWithHover
                className="w100 fit-contain"
                src={
                  blockImage && blockImage.src ? sanityImgUtil(blockImage, 500) : defaultImage.src
                }
                alt={
                  block.image && block.image.alt
                    ? block.image.alt
                    : Language.t('Default.defaultImageAlt')
                }
              />
            </Button>
          ) : (
            <ImgWithHover
              className="w100 fit-contain"
              src={blockImage && blockImage.src ? sanityImgUtil(blockImage, 500) : defaultImage.src}
              alt={
                block.image && block.image.alt
                  ? block.image.alt
                  : Language.t('Default.defaultImageAlt')
              }
            />
          )}
        </div>
        <div
          className={cx(
            `EventBlock__text-container p_4 col-12 md:col-6 flex flex-col bg-color-${block.backgroundColor}`,
            {
              'EventBlock__text-container--style-white-with-header':
                (hasWhiteBackground || hasBronzeBackground) && block.header,
              'EventBlock__text-container--style-white-no-header':
                (hasWhiteBackground || hasBronzeBackground) && !block.header,
              'EventBlock__text-container--style-black-with-header':
                hasBlackBackground && block.header,
              'EventBlock__text-container--style-black-no-header':
                hasBlackBackground && !block.header,
              'border-left-black': hasWhiteBackground || hasBronzeBackground,
              'border-right-black':
                breakpointIsMdDown &&
                ((!imageOnLeft && hasWhiteBackground) || (!imageOnLeft && hasBronzeBackground)),
              'border-left-white': !breakpointIsMdDown && imageOnLeft && hasBlackBackground,
              'border-top-black': !block.header,
            }
          )}
        >
          <div
            className={cx('EventBlock__event-information apercu text-md', {
              'color-green': hasWhiteBackground,
              'color-black': hasBronzeBackground,
              'color-white': hasBlackBackground,
            })}
          >
            {block.date && <p>{block.date}</p>}
            {block.time && <p>{block.time}</p>}
            {block.location && <p>{block.location}</p>}
          </div>
          {block.eventTitle && (
            <span
              className={cx('EventBlock__title apercu md:pr1 text-md', {
                'color-black': hasWhiteBackground || hasBronzeBackground,
                'color-white': hasBlackBackground,
              })}
            >
              {block.eventTitle}
            </span>
          )}
          {block.url && block.linkLabel && (
            <Button
              className={cx('link--style-hover-black text-md apercu', {
                'color-bronze': hasWhiteBackground || hasBlackBackground,
                'color-white': hasBronzeBackground,
              })}
              ariaLabel={Language.t('Global.readButtonAriaLabel', {
                articleTitle: block.linkLabel,
              })}
              to={block.url}
              label={block.linkLabel}
            />
          )}
          {block.details && (
            <p
              className={cx('apercu text-sm pt2 md:pt1_75', {
                'color-black': hasWhiteBackground || hasBronzeBackground,
                'color-white': hasBlackBackground,
              })}
            >
              {block.details}
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default withBreakpoints<Props>(EventBlock);
