import React from 'react';

import BlockHero from 'components/Hero';
import BlockImageText from 'components/ImageTextBlock';
import BlockGallery from 'components/GalleryBlock';
import BlockQuote from 'components/Quote';
import BlockVideo from 'components/VideoBlock';
import BlockText from 'components/TextBlock';
import BlockSocialMedia from 'components/SocialMediaBlock';
import BlockFacultyMembers from 'components/FacultyMembersBlock';
import BlockTopicsList from 'components/TopicsListBlock';
import BlockEvent from 'components/EventBlock';
import BlockArticleStudentWork from 'components/ArticleStudentWorkBlock';

import { Block } from 'types';

interface Props {
  blocks: Block[];
}

const BlockSwitch: React.FC<Props> = ({ blocks }) => {
  const Block = (block: Block) => {
    switch (block.type) {
      case 'heroBlock':
        return <BlockHero key={block.id} block={block} />;
      case 'imageTextBlock':
        return <BlockImageText key={block.id} block={block} />;
      case 'eventBlock':
        return <BlockEvent key={block.id} block={block} />;
      case 'galleryBlock':
        return <BlockGallery key={block.id} block={block} />;
      case 'quoteBlock':
        return <BlockQuote key={block.id} block={block} />;
      case 'videoBlock':
        return <BlockVideo key={block.id} block={block} />;
      case 'textBlock':
        return <BlockText key={block.id} block={block} />;
      case 'socialMediaBlock':
        return <BlockSocialMedia key={block.id} block={block} />;
      case 'facultyMembersBlock':
        return <BlockFacultyMembers key={block.id} facultyMembers={block.facultyMembers} />;
      case 'topicsListBlock':
        return <BlockTopicsList key={block.id} block={block} />;
      case 'studentWorkBlock':
        return <BlockArticleStudentWork key={block.id} block={block} />;
      default:
        return null;
    }
  };
  return <>{blocks && blocks.map((block: Block) => Block(block))}</>;
};

export default BlockSwitch;
