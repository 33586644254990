import React, { Component } from 'react';
import get from 'lodash/get';
import Flickity from 'flickity';
import cx from 'classnames';
import sanityImgUtil from 'utils/sanityImgUtil';
import debounce from 'lodash/debounce';
import Language from 'constants/Language';
import { defaultImages } from 'constants/Default';
import { DefaultImage } from '../constants/Default';
import generateTopicUrl from 'utils/generateTopicUrl';
import { Button, Img } from 'components/base';
import withBreakpoints, { InjectedProps as WithBreakpointsProps } from 'lib/withBreakpoints';

import { TopicLink } from 'types';

interface PassProps {
  topics: TopicLink[] | null;
}

type Props = PassProps & WithBreakpointsProps;

interface State {
  topics: TopicLink[] | null;
  selectedIndex: number;
  loadedImg: boolean[];
}

class FeaturedTopicsCarousel extends Component<Props, State> {
  wrapper: Element | null = null;
  flickity: Flickity | null = null;

  constructor(props: Props) {
    super(props);

    this.state = {
      topics: props.topics ? props.topics : null,
      selectedIndex: 0,
      loadedImg: [],
    };
  }

  handleResize = () => {
    if (this.flickity) {
      this.flickity.destroy();
    }

    this.initializeCarousel();
  };

  debounceHandleResize = debounce(this.handleResize, 300);

  componentDidMount() {
    window.addEventListener('resize', this.debounceHandleResize);
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    const { currentBreakpoint, topics } = this.props;
    const { loadedImg } = this.state;

    if (prevProps.currentBreakpoint !== currentBreakpoint) {
      this.handleResize();
    }

    if (
      topics &&
      topics.length <= loadedImg.length &&
      prevState.loadedImg.length !== loadedImg.length
    ) {
      this.handleResize();
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.debounceHandleResize);
  }

  initializeCarousel = () => {
    if (this.wrapper) {
      this.flickity = new Flickity(this.wrapper, {
        initialIndex: 0,
        wrapAround: true,
        draggable: true,
        freeScroll: false,
        prevNextButtons: true,
        accessibility: true,
        pageDots: false,
        on: {
          ready: () => {
            setTimeout(() => this.flickity && this.flickity.resize(), 0);
          },
          select: () => {
            if (this.flickity) {
              this.setState({ selectedIndex: this.flickity.selectedIndex });
            }
          },
        },
      });
    }
  };

  handleImgLoad = () => {
    this.setState((state) => ({ loadedImg: state.loadedImg.concat([true]) }));
  };

  render() {
    const { topics, selectedIndex } = this.state;
    const topicsLength = get(this, 'state.topics', []).length;
    const defaultImage = defaultImages[3];

    return (
      <div className="FeaturedTopicsCarousel pb4">
        <div className="py7 md:py4 bg-color-black grid-white flex flex-col items-center">
          <div
            className={cx('FeaturedTopicsCarousel__container w100 overflow-hidden', {
              'FeaturedTopicsCarousel__container--multi-image': topicsLength && topicsLength > 1,
            })}
            ref={(node) => (this.wrapper = node)}
          >
            {topics &&
              topics.map((topic: TopicLink) =>
                topic.heroImage && topic.heroImage.src ? (
                  <Img
                    key={topic.heroImage.src}
                    className="FeaturedTopicsCarousel__img px_5 md:px1 xl:px4 fit-contain"
                    src={sanityImgUtil(topic.heroImage, 500)}
                    alt={
                      topic.heroImage.alt ||
                      topic.heroImage.caption ||
                      Language.t('Default.defaultImageAlt')
                    }
                    onImgLoad={this.handleImgLoad}
                  />
                ) : (
                  <Img
                    key={topic.id}
                    className="FeaturedTopicsCarousel__img px_5 md:px1 xl:px4 fit-contain"
                    src={defaultImage.src}
                    alt={DefaultImage.alt}
                    onImgLoad={this.handleImgLoad}
                  />
                )
              )}
          </div>
        </div>
        <div className="border-top-white col-12 flex flex-row justify-between bg-color-black p_4 apercu relative">
          <div className="col-12 md:pt_75 color-bronze flex justify-center">
            {topics && topics[selectedIndex].slug && (
              <Button
                ariaLabel={Language.t('FeaturedTopicsCarousel.topicTitleButtonAriaLabel', {
                  topic: topics[selectedIndex].title,
                })}
                className="link--style-hover-bronze-darker color-bronze text-decoration-none bg-color-transparent text-md"
                to={generateTopicUrl(topics[selectedIndex].slug)}
                label={topics && topics[selectedIndex].title}
              />
            )}
          </div>
          <div className="absolute r0 col-2 color-white flex justify-end pr_4 text-sm">
            {selectedIndex + 1}/{topics && topics.length}
          </div>
        </div>
      </div>
    );
  }
}

export default withBreakpoints<Props>(FeaturedTopicsCarousel);
