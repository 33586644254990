import React from 'react';
import get from 'lodash/get';
import { View, GenericPagePagination, Block } from 'types';
import ApiClient from 'lib/ApiClient';
import Language from 'constants/Language';
import { GENERIC_PAGE_PAGINATION_PER_PAGE } from 'constants/Pagination';
import { RouteMap } from 'constants/RouteMap';
import BlockSwitch from 'components/BlockSwitch';
import { Button } from 'components/base';
import { GenericPage as Meta } from 'components/Meta';
import PageNotFound from 'components/PageNotFound';

interface Props {
  model: GenericPagePagination | null;
}

interface State {
  paginationPageNumber: number;
  paginationTotalNumberOfBlocks: number;
  blocks: Block[];
}

class GenericPageView extends View<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      paginationPageNumber: get(props, 'model.page'),
      paginationTotalNumberOfBlocks: get(props, 'model.total'),
      blocks: get(props, 'model.genericPage.blocks'),
    };
  }

  componentDidUpdate(prevProps: Props) {
    if (get(prevProps, 'model.genericPage.id') !== get(this, 'props.model.genericPage.id')) {
      this.setState((state: State) => ({
        paginationPageNumber: get(this, 'props.model.page'),
        paginationTotalNumberOfBlocks: get(this, 'props.model.total'),
        blocks: get(this, 'props.model.genericPage.blocks'),
      }));
    }
  }

  onLoadMore = () => {
    const { blocks, paginationPageNumber, paginationTotalNumberOfBlocks } = this.state;
    const { pathname } = window.location;

    if (!blocks) {
      return null;
    }

    // check if any pages are left
    if (
      paginationTotalNumberOfBlocks <= paginationPageNumber * GENERIC_PAGE_PAGINATION_PER_PAGE ||
      paginationTotalNumberOfBlocks < GENERIC_PAGE_PAGINATION_PER_PAGE
    ) {
      return null;
    } else {
      const slug = pathname === RouteMap.HOME.path ? RouteMap.RESEARCH.path : pathname;

      return ApiClient.fetchGenericPagePagination(slug, paginationPageNumber + 1).then(
        (response: GenericPagePagination) =>
          this.setState((state: State) => ({
            paginationPageNumber: response.page,
            paginationTotalNumberOfBlocks: response.total,
            blocks: state.blocks.concat(get(response, 'genericPage.blocks')),
          }))
      );
    }
  };

  render() {
    const { model } = this.props;
    const { paginationTotalNumberOfBlocks, paginationPageNumber } = this.state;
    const pageNotFoundModel = get(model, 'globalSettings.pageNotFoundModel');

    if (!model || !model.genericPage) {
      return <PageNotFound pageNotFoundModel={pageNotFoundModel} />;
    }

    const showLoadMore =
      paginationTotalNumberOfBlocks > paginationPageNumber * GENERIC_PAGE_PAGINATION_PER_PAGE;

    return (
      <div className="GenericPageView">
        <h1 className="safe-visibility-hidden">{get(model, 'genericPage.title', '')}</h1>
        <Meta title={get(model, 'genericPage.title', '')} seo={get(model, 'genericPage.seo')} />
        <BlockSwitch blocks={this.state.blocks} />
        {showLoadMore && (
          <div className="GenericPageView__pagination col-12">
            <Button
              className="col-12 link--style-hover-black text-left load-more-container-padding border-left-black border-right-black text-decoration-none text-lg apercu color-bronze bg-color-white"
              ariaLabel={Language.t('Global.showMore')}
              onClick={this.onLoadMore}
              label={Language.t('Global.showMore')}
            />
          </div>
        )}
      </div>
    );
  }
}

export default GenericPageView;
