import React, { Component } from 'react';
import cx from 'classnames';
import get from 'lodash/get';
import generateArticleUrl from 'utils/generateArticleUrl';
import sanityImgUtil from 'utils/sanityImgUtil';
import Language from 'constants/Language';

import { Img, Button } from 'components/base';
import Model3D from 'components/Model3D';
import AuthorList from 'components/AuthorList';
import { BlockHero, BackgroundColorType } from 'types';

interface Props {
  block: BlockHero;
  className?: string;
}

class Hero extends Component<Props> {
  render() {
    const {
      block: { article, heroImage, model },
      className,
    } = this.props;

    const image = get(heroImage, 'src') ? heroImage : get(article, 'heroImage');
    const hasModel = get(model, 'src');
    const hasHeroImageOrModel = image || hasModel;

    return (
      <div className={cx('Hero grid-black-12-columns', className)}>
        {hasHeroImageOrModel && (
          <div className="Hero__outer-image-container">
            <div className="Hero__image-container flex items-center justify-center mxauto relative">
              {!!image && (
                <div className="w100 flex items-center justify-center">
                  <Img
                    className="Hero__image image-drop-shadow"
                    src={sanityImgUtil(image)}
                    alt={
                      image.alt || image.caption || Language.t('Default.defaultImageAlt')
                    }
                  />
                </div>
              )}
              {model && <Model3D src={model.src} />}
            </div>
          </div>
        )}
        {article && article.title && (
          <div
            className={cx(
              'Hero__article-title block-title-container-padding border-left-black border-right-black border-top-black col-12 bg-color-white flex flex-col hyphens'
            )}
          >
            <Button
              to={generateArticleUrl(article.slug)}
              className="Hero__title line-height-xs inline text-decoration-none text-lg apercu color-black md:pr1"
              ariaLabel={Language.t('Global.readButtonAriaLabel', {
                articleTitle: article.title
                  ? article.title
                  : Language.t('Global.article'),
              })}
            >
              <span>{article.title}</span>
            </Button>
            {article && article.authorsInfo && (
              <AuthorList
                bgColor={BackgroundColorType.WHITE}
                authorsInfo={article.authorsInfo}
                topic={article.topics[0]}
              />
            )}
          </div>
        )}
      </div>
    );
  }
}

export default Hero;
