import React from 'react';
import BlockContent from '@sanity/block-content-to-react';

import { PortableText } from 'types';
import { BodyLinks, HeaderLinkWithList, Green } from 'constants/PortableTextSerializer';

interface Props {
  contents: PortableText;
}

const TextBlockContent: React.FC<Props> = ({ contents }) => {
  return (
    <BlockContent
      className="apercu text-sm"
      blocks={contents}
      serializers={{
        types: {
          bodyLinks: BodyLinks,
          headerLinkWithList: HeaderLinkWithList
        },
        marks: { green: Green },
      }}
    />
  );
};

export default TextBlockContent;
