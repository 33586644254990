import React from 'react';
import cx from 'classnames';

import { BlockQuote } from 'types';

interface Props {
  block: BlockQuote;
  className?: string;
}

const Quote: React.FC<Props> = ({ className, block }) => {
  const { text } = block;

  return (
    <div className={cx('Quote bg-color-white color-black apercu border-black', className)}>
      <div className="col-10 mxauto">{text}</div>
    </div>
  );
};

export default Quote;
