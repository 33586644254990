import React, { useState, useEffect, useCallback } from 'react';
import Language from 'constants/Language';
import cx from 'classnames';
import { FACULTY_MEMBERS_PAGINATION_PER_PAGE } from 'constants/Pagination';
import { FacultyMember } from 'types';
import { Button } from 'components/base';
import generateAuthorName from 'utils/generateAuthorName';
import sortFacultyMembersByLastName from 'utils/sortFacultyMembersByLastName';

interface Props {
  facultyMembers: FacultyMember[];
  hasSearchStyling?: boolean;
  className?: string;
}

const FacultyMembersBlock: React.FC<Props> = ({ facultyMembers, hasSearchStyling, className }) => {
  const [sortedFacultyMembers, setSortedFacultyMembers] = useState(
    sortFacultyMembersByLastName(facultyMembers)
  );
  const [amountShown, setAmountShown] = useState(FACULTY_MEMBERS_PAGINATION_PER_PAGE);

  const didClickShowMore = useCallback(() => {
    const nextAmountShown = Math.min(
      amountShown + FACULTY_MEMBERS_PAGINATION_PER_PAGE,
      sortedFacultyMembers.length
    );
    setAmountShown(nextAmountShown);
  }, [amountShown, sortedFacultyMembers]);

  useEffect(() => {
    setSortedFacultyMembers(sortFacultyMembersByLastName(facultyMembers));
    setAmountShown(FACULTY_MEMBERS_PAGINATION_PER_PAGE);
  }, [facultyMembers]);

  const displayedFacultyMembers = sortedFacultyMembers.slice(0, amountShown);
  const hasMore = sortedFacultyMembers.length > displayedFacultyMembers.length;

  if (!facultyMembers.length) {
    return null;
  }

  return (
    <div
      className={cx('FacultyMembersBlock flex flex-col', className, {
        'border-top-black border-right-black border-left-black': !hasSearchStyling,
      })}
    >
      <div
        className={cx('FacultyMembersBlock__title apercu border-bottom-black flex', {
          'FacultyMembersBlock__title--style-search text-md color-green flex-row justify-between items-end': hasSearchStyling,
          'color-black text-lg flex-col': !hasSearchStyling,
        })}
      >
        {hasSearchStyling
          ? Language.t('FacultyMembersBlock.shortTitle')
          : Language.t('FacultyMembersBlock.title')}
      </div>
      <div className="FacultyMembersBlock__list flex flex-col apercu text-xs overflow-x-auto">
        {displayedFacultyMembers.map((member: FacultyMember) => (
          <div
            key={member.id}
            className="FacultyMembersBlock__item-container break-word color-black flex flex-row"
          >
            <div
              className={cx('FacultyMembersBlock__item col-2 flex flex-col', {
                'FacultyMembersBlock__item--style-search': hasSearchStyling,
              })}
            >
              {generateAuthorName(member.firstName, member.lastName)}
              <span className="color-green">{member.jobTitle}</span>
            </div>
            <div
              className={cx('FacultyMembersBlock__item col-2 flex flex-col', {
                'FacultyMembersBlock__item--style-search': hasSearchStyling,
              })}
            >
              <Button
                ariaLabel={Language.t('FacultyMembersBlock.emailAddressButtonAriaLabel', {
                  facultyMember: generateAuthorName(member.firstName, member.lastName),
                })}
                className="link--style-hover-bronze-darker color-bronze bg-color-transparent text-xs apercu flex"
                to={`mailto:${member.emailAddress}`}
                label={member.emailAddress}
              />
              {member.websiteLinkLabel && member.websiteUrl && (
                <Button
                  ariaLabel={Language.t('FacultyMembersBlock.websiteButtonAriaLabel', {
                    website: member.websiteUrl,
                  })}
                  className="link--style-hover-bronze-darker color-bronze bg-color-transparent text-xs apercu flex"
                  to={member.websiteUrl}
                  label={member.websiteLinkLabel}
                />
              )}
            </div>
            <div className="FacultyMembersBlock__item col-2 flex">
              <span>{member.department.name}</span>
            </div>
            <div className="FacultyMembersBlock__item col-3 flex">
              {member.areasOfInterest && <span>{member.areasOfInterest}</span>}
            </div>
            <div className="FacultyMembersBlock__item col-3 flex">
              {member.courses && <span>{member.courses}</span>}
            </div>
          </div>
        ))}
      </div>
      {hasMore && (
        <div className="FacultyMembersBlock__pagination col-12 border-top-black">
          <Button
            className="FacultyMembersBlock__list-item text-left py2 p_4 bg-color-white text-decoration-none link--style-hover-bronze-darker apercu text-xs color-bronze"
            ariaLabel={Language.t('Global.showMore')}
            onClick={didClickShowMore}
            label={Language.t('Global.showMore')}
          />
        </div>
      )}
    </div>
  );
};

export default FacultyMembersBlock;
