import React from 'react';
import cx from 'classnames';
import get from 'lodash/get';
import withBreakpoints, {
  Breakpoints,
  InjectedProps as WithBreakpointsProps,
} from 'lib/withBreakpoints';

import Language from 'constants/Language';
import { defaultImages } from 'constants/Default';
import sanityImgUtil from 'utils/sanityImgUtil';
import generateArticleUrl from 'utils/generateArticleUrl';
import { ImgWithHover, Button } from 'components/base';
import AuthorList from 'components/AuthorList';
import { BlockImageText, BackgroundColorType } from 'types';

interface PassedProps {
  block: BlockImageText;
  className?: string;
}

type Props = PassedProps & WithBreakpointsProps;

const ImageTextBlock: React.FC<Props> = ({ className, block, currentBreakpoint }) => {
  const { article } = block;
  const blockImage = get(block, 'thumbnailImage') || get(block, 'article.heroImage');
  const defaultImage = defaultImages[0];
  const hasBlackBackground = block.backgroundColor === BackgroundColorType.BLACK;
  const hasBronzeBackground = block.backgroundColor === BackgroundColorType.BRONZE;
  const hasWhiteBackground = block.backgroundColor === BackgroundColorType.WHITE;
  const imageOnLeft = block.alignment === 'image-on-left';
  const breakpointIsMdDown = [
    Breakpoints.MEDIUM.label,
    Breakpoints.SMALL.label,
    Breakpoints.SMALL_EXTRA_SMALL.label,
    Breakpoints.EXTRA_SMALL.label,
  ].includes(currentBreakpoint);

  return (
    <div
      className={cx(`ImageTextBlock flex flex-col bg-color-${block.backgroundColor}`, className, {
        'border-right-black':
          ((hasWhiteBackground || hasBronzeBackground) && imageOnLeft) || hasBlackBackground,
      })}
    >
      {block.header && (
        <div
          className={cx(
            `ImageTextBlock__header block-title-container-padding apercu text-lg col-12 bg-color-${block.backgroundColor}`,
            {
              'color-black border-top-black border-left-black':
                hasWhiteBackground || hasBronzeBackground,
              'color-white': hasBlackBackground,
            }
          )}
        >
          {block.header}
        </div>
      )}
      <div
        className={cx('ImageTextBlock__container flex flex-col module-image-min-height', {
          'md:flex-row': imageOnLeft,
          'md:flex-row-reverse': !imageOnLeft,
        })}
      >
        {article && (
          <div
            className={cx('md:col-6 z-3 flex p3 lg:p4 flex items-center justify-center', {
              'border-right-black': !imageOnLeft && hasWhiteBackground,
              'border-left-black border-bottom-black': imageOnLeft && hasBlackBackground,
              'border-left-black': hasBlackBackground && breakpointIsMdDown,
              'grid-white': hasBlackBackground,
              'grid-black-12-columns': hasWhiteBackground || hasBronzeBackground,
            })}
          >
            <Button
              containerClassName="flex w100 z-5"
              className="w100 h100 flex items-center justify-center"
              ariaLabel={Language.t('Global.readButtonAriaLabel', {
                articleTitle: article.title ? article.title : Language.t('Global.article'),
              })}
              to={generateArticleUrl(article.slug)}
            >
              <ImgWithHover
                className="w100 fit-contain"
                src={blockImage ? sanityImgUtil(blockImage, 500) : defaultImage.src}
                alt={blockImage ? blockImage.alt : Language.t('Default.defaultImageAlt')}
              />
            </Button>
          </div>
        )}
        <div
          className={cx(
            `ImageTextBlock__text-container py_4 px_625 col-12 md:col-6 flex flex-col bg-color-${block.backgroundColor}`,
            {
              'border-top-black': hasWhiteBackground || hasBronzeBackground,
              'border-top-white': hasBlackBackground,
              'border-left-black': hasWhiteBackground || hasBronzeBackground,
              'border-right-black':
                breakpointIsMdDown &&
                ((!imageOnLeft && hasWhiteBackground) || (!imageOnLeft && hasBronzeBackground)),
              'border-left-white': !breakpointIsMdDown && imageOnLeft && hasBlackBackground,
            }
          )}
        >
          {article && (article.title || article.briefTitle) && (
            <div className="ImageTextBlock__title-container hyphens">
              {(article.title || article.briefTitle) && (
                <span
                  className={cx(
                    'ImageTextBlock__title apercu line-height-xs hyphens text-lg md:pr1',
                    {
                      'color-black': hasWhiteBackground || hasBronzeBackground,
                      'color-white': hasBlackBackground,
                    }
                  )}
                >
                  {article.briefTitle || article.title}
                </span>
              )}
              <div>
                <Button
                  className={cx('ImageTextBlock__button text-lg apercu', {
                    'ImageTextBlock__button--style-black': hasBlackBackground,
                    'ImageTextBlock__button--style-white': hasWhiteBackground,
                    'ImageTextBlock__button--style-bronze': hasBronzeBackground,
                  })}
                  ariaLabel={Language.t('Global.readButtonAriaLabel', {
                    articleTitle: article.title ? article.title : Language.t('Global.article'),
                  })}
                  to={generateArticleUrl(article.slug)}
                >
                  {Language.t('Global.read')}
                </Button>
              </div>
              {article && article.authorsInfo && (
                <AuthorList
                  bgColor={block.backgroundColor}
                  authorsInfo={article.authorsInfo}
                  topic={article.topics[0]}
                />
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default withBreakpoints<Props>(ImageTextBlock);
