import React from 'react';
import cx from 'classnames';
import generateTopicUrl from 'utils/generateTopicUrl';
import Language from 'constants/Language';

import { Button } from 'components/base';
import { TopicLink } from 'types';

interface Props {
  topics: TopicLink[];
  className?: string;
}

const TopicsListBlock: React.FC<Props> = ({ className, topics }) => {
  return (
    <div className={cx('TopicsListBlock bg-color-black', className)}>
      {topics.map((topic: TopicLink) => (
        <div
          key={topic.title}
          className="TopicsListBlock__list-item flex flex-row apercu py2 px_625 border-top-white"
        >
          <div className="col-8 color-white">{topic.title}</div>
          <div className="col-4 flex justify-end">
            <Button
              ariaLabel={Language.t('TopicsList.exploreTopicButtonAriaLabel', {
                topic: topic.title,
              })}
              className="link--style-hover-bronze-darker color-bronze text-decoration-none bg-color-transparent"
              to={generateTopicUrl(topic.slug)}
            >
              {Language.t('TopicsList.exploreTopic')}
            </Button>
          </div>
        </div>
      ))}
    </div>
  );
};

export default TopicsListBlock;
