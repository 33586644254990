import React from 'react';
import get from 'lodash/get';

import cx from 'classnames';

import { BlockText, LinkListVariantType } from 'types';
import { LinkList } from 'components/base';
import TextBlockContent from 'components/TextBlockContent';

interface Props {
  block: BlockText | null;
}

const TextBlock: React.FC<Props> = ({ block }) => {
  const notificationBar = document.querySelector('.NotificationBar');

  if (!block) {
    return null;
  }

  const hasRelatedLinks = get(block, 'relatedLinks.links', []);

  return (
    <div className="TextBlock border-top-black border-right-black border-left-black p1">
      {block.header && (
        <div className="pb2 md:pb4">
          <div className="TextBlock__header text-lg col-12 md:col-8 color-green">
            {block.header}
          </div>
          {block.subheading && (
            <div className="TextBlock__subheading text-lg col-12 md:col-8 color-black">
              {block.subheading}
            </div>
          )}
        </div>
      )}
      <div className="TextBlock__outer-content-container flex flex-col md:flex-row lg:col-10 mxauto justify-between relative">
        <div
          className={cx('TextBlock__content-container', {
            'lg:col-8': hasRelatedLinks,
            mxauto: !hasRelatedLinks.length,
          })}
        >
          <div className="TextBlock__article-content text-block-body">
            <TextBlockContent contents={block.content} />
          </div>
          {!!hasRelatedLinks.length && (
            <div className="article-links-container block xl-lg:none pb2">
              <LinkList variant={LinkListVariantType.ARTICLE_SIDEBAR} data={block.relatedLinks} />
            </div>
          )}
        </div>
        {!!hasRelatedLinks.length && (
          <div className="article-links-container none xl-lg:mb1 xl-lg:block  xl-lg:col-3 xl-lg:block h100 absolute t0 r0">
            <div
              className={cx('article-links xl-lg:sticky', {
                'TextBlock__article-links--style-notification-active': notificationBar,
              })}
            >
              <LinkList variant={LinkListVariantType.ARTICLE_SIDEBAR} data={block.relatedLinks} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default TextBlock;
