import React from 'react';
import cx from 'classnames';
import Language from 'constants/Language';
import generateArticleUrl from '../utils/generateArticleUrl';

import { BlockVideo, BackgroundColorType } from 'types';

import { Video, Button } from 'components/base';
import AuthorList from 'components/AuthorList';

interface Props {
  block: BlockVideo;
}

const VideoBlock: React.FC<Props> = ({ block }) => {
  const { article } = block;

  return (
    <div className="VideoBlock grid-black-12-columns">
      <Video variant="generic" videoId={block.videoId} />
      {article && article.title && (
        <div
          className={cx(
            'block-title-container-padding border-left-black border-top-black border-right-black col-12 bg-color-white flex flex-col lg:block'
          )}
        >
          <span className="text-lg apercu color-black md:pr1">{article.title}</span>
          <div>
            <Button
              to={generateArticleUrl(article.slug)}
              className="link--style-hover-black text-lg apercu color-bronze inline text-decoration-none"
              ariaLabel={Language.t('Global.read')}
            >
              {Language.t('Global.read')}
            </Button>
          </div>
          {article && article.authorsInfo && (
            <AuthorList
              bgColor={BackgroundColorType.WHITE}
              authorsInfo={article.authorsInfo}
              topic={article.topics[0]}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default VideoBlock;
