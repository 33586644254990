import React, { useState } from 'react';
import cx from 'classnames';
import Language from 'constants/Language';
import { TOPICS_LIST_PAGINATION_PER_PAGE } from 'constants/Pagination';
import { BlockTopicsList, TopicLink } from 'types';
import TopicsList from 'components/TopicsList';
import { Button } from 'components/base';
import FeaturedTopicsCarousel from 'components/FeaturedTopicsCarousel';

interface Props {
  block: BlockTopicsList;
  className?: string;
}

const TopicsListBlock: React.FC<Props> = ({ className, block }) => {
  const listOfTopics = block.listOfTopics;

  const [displayedTopics, setDisplayedTopics] = useState(
    listOfTopics.slice(0, TOPICS_LIST_PAGINATION_PER_PAGE)
  );

  const [remainingTopics, setRemainingTopics] = useState(
    listOfTopics.length > TOPICS_LIST_PAGINATION_PER_PAGE
      ? listOfTopics.slice(TOPICS_LIST_PAGINATION_PER_PAGE)
      : []
  );
  const [hasMore, setHasMore] = useState(!!remainingTopics.length);
  const _setDisplayedTopics = () => {
    let nextSetOfTopics: TopicLink[] = [];
    let remainingTopicsCloned = remainingTopics;

    for (let i = 0; i < TOPICS_LIST_PAGINATION_PER_PAGE; i++) {
      if (!!remainingTopicsCloned.length) {
        const nextTopic = remainingTopicsCloned.shift();
        if (nextTopic) {
          nextSetOfTopics.push(nextTopic);
        }
      }
    }

    if (!!nextSetOfTopics.length) {
      setDisplayedTopics(displayedTopics.concat(nextSetOfTopics));
      setRemainingTopics(remainingTopicsCloned);
    }

    if (!remainingTopicsCloned.length) {
      setHasMore(false);
    }
  };

  return (
    <div className={cx('TopicsListBlock bg-color-black', className)}>
      <div className="TopicsListBlock__title text-lg apercu color-white bg-color-black">
        {block.title}
      </div>
      <FeaturedTopicsCarousel topics={block.featuredTopics} />
      <TopicsList topics={displayedTopics} />
      {hasMore && (
        <div className="TopicsListBlock__pagination col-12 border-top-white">
          <Button
            className="TopicsListBlock__list-item text-left py2 px_625 bg-color-black text-decoration-none link--style-hover-bronze-darker apercu color-bronze"
            ariaLabel={Language.t('TopicsList.moreAreasButtonAriaLabel')}
            onClick={_setDisplayedTopics}
            label={Language.t('TopicsList.moreAreas')}
          />
        </div>
      )}
    </div>
  );
};

export default TopicsListBlock;
